import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from 'react-helmet';
import { ChevronLeft, Plus, Trash } from 'lucide-react';

const SystemAdminOnboardingRequirements = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [requirements, setRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState({ name: '', is_mandatory: true });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Admin Onboarding Requirements',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      fetchRequirements();
    }
  }, [selectedClient]);

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('client_id, name');

      if (error) throw error;
      setClients(data);
    } catch (error) {
      console.error('Error fetching clients:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchRequirements = async () => {
    try {
      const { data, error } = await supabase
        .from('onboarding_requirements')
        .select('*')
        .eq('client_id', selectedClient);

      if (error) throw error;
      setRequirements(data);
    } catch (error) {
      console.error('Error fetching requirements:', error.message);
    }
  };

  const handleAddRequirement = async () => {
    try {
      const { data, error } = await supabase
        .from('onboarding_requirements')
        .insert({
          client_id: selectedClient,
          requirement_name: newRequirement.name,
          is_mandatory: newRequirement.is_mandatory
        })
        .select();

      if (error) throw error;
      setRequirements([...requirements, data[0]]);
      setNewRequirement({ name: '', is_mandatory: true });
    } catch (error) {
      console.error('Error adding requirement:', error.message);
      alert('Error adding requirement. Please try again.');
    }
  };

  const handleDeleteRequirement = async (id) => {
    try {
      const { error } = await supabase
        .from('onboarding_requirements')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setRequirements(requirements.filter(req => req.id !== id));
    } catch (error) {
      console.error('Error deleting requirement:', error.message);
      alert('Error deleting requirement. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Admin Onboarding Requirements | fetchConsultant</title>
        <meta name="description" content="Manage onboarding requirements for clients on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
            <div className="max-w-md mx-auto">
              <div className="flex items-center space-x-5">
                <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                  <h2 className="leading-relaxed">Manage Onboarding Requirements</h2>
                </div>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div>
                    <label htmlFor="client" className="block text-sm font-medium text-gray-700">Select Client</label>
                    <select
                      id="client"
                      value={selectedClient}
                      onChange={(e) => setSelectedClient(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select a client</option>
                      {clients.map((client) => (
                        <option key={client.client_id} value={client.client_id}>{client.name}</option>
                      ))}
                    </select>
                  </div>
                  {selectedClient && (
                    <>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Current Requirements</h3>
                        <ul className="mt-3 space-y-3">
                          {requirements.map((req) => (
                            <li key={req.id} className="flex items-center justify-between">
                              <span>{req.requirement_name} ({req.is_mandatory ? 'Mandatory' : 'Optional'})</span>
                              <button onClick={() => handleDeleteRequirement(req.id)} className="text-red-600">
                                <Trash size={20} />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Add New Requirement</h3>
                        <input
                          type="text"
                          value={newRequirement.name}
                          onChange={(e) => setNewRequirement({ ...newRequirement, name: e.target.value })}
                          placeholder="Requirement name"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <div className="mt-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              checked={newRequirement.is_mandatory}
                              onChange={(e) => setNewRequirement({ ...newRequirement, is_mandatory: e.target.checked })}
                              className="form-checkbox h-5 w-5 text-indigo-600"
                            />
                            <span className="ml-2 text-gray-700">Mandatory</span>
                          </label>
                        </div>
                        <button
                          onClick={handleAddRequirement}
                          className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <Plus className="mr-2" size={20} />
                          Add Requirement
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemAdminOnboardingRequirements;