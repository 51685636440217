import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';

const ClientOfferContract = () => {
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    contract_rate: '',
    start_date: '',
    end_date: '',
    invoicing_terms: '',
    payment_terms: '',
  });

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Offer Contract',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
    fetchApplicantDetails();
  }, []);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchApplicantDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          users!user_jobs_user_id_fkey (
            name,
            login_email
          ),
          job:job_id (
            title,
            client_id,
            duration
          )
        `)
        .eq('user_job_id', userJobId)
        .single();
  
      if (error) throw error;
      setApplicant(data);
      setFormData(prevData => ({
        ...prevData,
        contract_rate: data.consultant_rate,
        start_date: data.available_start_date,
      }));
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('engagements')
        .insert([
          {
            user_job_id: userJobId,
            consultant_id: applicant.user_id,
            client_id: applicant.job.client_id,
            job_id: applicant.job_id,
            contract_rate: formData.contract_rate,
            start_date: formData.start_date,
            end_date: formData.end_date,
            status: 'Offered',
            invoicing_terms: formData.invoicing_terms,
            payment_terms: formData.payment_terms,
          }
        ])
        .select()
        .single();

      if (error) throw error;

      // Update user_jobs status
      await supabase
        .from('user_jobs')
        .update({ status: 'Contract Offered' })
        .eq('user_job_id', userJobId);

      alert('Contract offer sent successfully!');
      navigate('/app/applicants/' + applicant.job_id);
    } catch (error) {
      console.error('Error sending contract offer:', error);
      alert('Failed to send contract offer. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!applicant) return <div>Applicant not found</div>;

  return (
    <>
      <Helmet>
        <title>Offer Contract | fetchConsultant</title>
        <meta name="description" content="Offer a contract to a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="offer-contract" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Offer Contract</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              <h2 className="text-2xl font-semibold mb-4">Offer Contract to {applicant.users.name}</h2>
              <p className="mb-4">For position: {applicant.job.title}</p>
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="contract_rate" className="fetch-label">Contract Rate ($/hour)</label>
                  <input
                    type="number"
                    id="contract_rate"
                    name="contract_rate"
                    value={formData.contract_rate}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="start_date" className="fetch-label">Start Date</label>
                  <input
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="end_date" className="fetch-label">End Date</label>
                  <input
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="invoicing_terms" className="fetch-label">Invoicing Terms</label>
                  <textarea
                    id="invoicing_terms"
                    name="invoicing_terms"
                    value={formData.invoicing_terms}
                    onChange={handleInputChange}
                    className="fetch-input"
                    rows="3"
                  ></textarea>
                </div>
                <div>
                  <label htmlFor="payment_terms" className="fetch-label">Payment Terms</label>
                  <textarea
                    id="payment_terms"
                    name="payment_terms"
                    value={formData.payment_terms}
                    onChange={handleInputChange}
                    className="fetch-input"
                    rows="3"
                  ></textarea>
                </div>
                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="fetch-button bg-gray-500 hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="fetch-button"
                  >
                    Send Contract Offer
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientOfferContract;