import { getAnalytics, logEvent } from "firebase/analytics";
import { ArrowLongLeftIcon, CheckIcon, HandThumbUpIcon, HomeIcon, UserIcon } from '@heroicons/react/20/solid';
import { Download, Menu, PlayCircle, Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import TabsUnderlineBadges from './ui/TabsUnderlineBadges';
import { format, formatDistanceToNow } from 'date-fns';
import NoteModal from './ui/NoteModal';
import ReviewModal from "./ui/ReviewModal.js";

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const renderStars = (rating) => {
  const stars = [];
  const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
  for (let i = 1; i <= 5; i++) {
    let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
    if (starFill === 1) {
      // Full star
      stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
    } else if (starFill > 0) {
      // Partial star
      stars.push(
        <div key={i} className="relative">
          <Star size={20} fill="none" stroke="gold" />
          <div
            className="absolute top-0 left-0 overflow-hidden"
            style={{ width: `${starFill * 100}%` }}
          >
            <Star size={20} fill="gold" stroke="gold" />
          </div>
        </div>
      );
    } else {
      // Empty star
      stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
    }
  }
  return stars;
};

const ApplicantDetails = () => {
  const [applicant, setApplicant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [interviewNotes, setInterviewNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [videoLoadError, setVideoLoadError] = useState(null);
  const [timeline, setTimeline] = useState([]);
  const [activeTab, setActiveTab] = useState('All Notes');
  const [activeReviewTab, setActiveReviewTab] = useState('All');
  const [ratingLabel, setRatingLabel] = useState('Overall Rating');
  const [newNote, setNewNote] = useState('');
  const [selectedReview, setSelectedReview] = useState(null);
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [comments, setComments] = useState([]);

  const handleViewFullReview = (review) => {
    setSelectedReview(review);
  };

  const closeReviewModal = () => {
    setSelectedReview(null);
  };

  // Single initial data fetch
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const analytics = getAnalytics();
        logEvent(analytics, 'page_view', {
          page_title: 'Applicant Details',
          page_location: window.location.href,
          page_path: window.location.pathname,
        });

        // Fetch user data
        const { data: { user: currentUser } } = await supabase.auth.getUser();
        if (!currentUser) throw new Error("No authenticated user found");

        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('user_id', currentUser.id)
          .single();

        if (userError) throw userError;
        setUser(userData);

        // Fetch applicant details
        const { data: applicantData, error: applicantError } = await supabase
          .from('user_jobs')
          .select(`
            *,
            users!user_jobs_user_id_fkey (
              user_id, name, about, photo, city, state, linkedin_url,
              former_epic, average_rating, average_peer_rating,
              average_manager_rating, resume, current_project_end_date,
              user_epic_certifications ( epic_applications (name) )
            ),
            job:job_id ( title, video_questions ),
            referrer:referred_by ( user_id, name )
          `)
          .eq('user_job_id', userJobId)
          .single();

        if (applicantError) throw applicantError;
        setApplicant(applicantData);

        // Update last viewed
        await supabase
          .from('user_jobs')
          .update({ last_viewed_by_client: new Date().toISOString() })
          .eq('user_job_id', userJobId);

        // Fetch reviews
        const { data: reviewsData, error: reviewsError } = await supabase
          .from('reviews')
          .select(`
            *,
            reviewer:reviewer_id(
              name, user_id, work_email, phone, created_at
            )
          `)
          .eq('consultant_id', applicantData.users.user_id)
          .order('created_at', { ascending: false });

        if (reviewsError) throw reviewsError;
        setReviews(reviewsData);
        
        // Fetch interview notes
        const { data: notesData, error: notesError } = await supabase
          .from('interview_notes')
          .select('*, notewriter:notewriter_id(name, employer, photo)')
          .eq('consultant_id', applicantData.users.user_id)
          .eq('notewriter.employer', userData.employer)
          .order('created_at', { ascending: false });

        if (notesError) throw notesError;
        setInterviewNotes(notesData);

        // Fetch timeline
        const { data: timelineData, error: timelineError } = await supabase
          .from('user_job_statuses')
          .select(`
            id,
            status,
            changed_at,
            changed_by,
            users:changed_by(name)
          `)
          .eq('user_job_id', userJobId)
          .order('changed_at', { ascending: false });

        if (timelineError) throw timelineError;

        const formattedTimeline = timelineData.map(event => ({
          id: event.id,
          type: eventTypes.advanced,
          content: `Status changed to ${event.status}`,
          target: event.status,
          date: formatDistanceToNow(new Date(event.changed_at), { addSuffix: true }),
          datetime: event.changed_at,
          changedBy: event.users?.name || 'System'
        }));

        setTimeline(formattedTimeline);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [userJobId]);

  // Comments-specific useEffect
  useEffect(() => {
    const fetchComments = async () => {
      if (!applicant?.users?.user_id || !user?.employer) return;
      
      try {
        const { data, error } = await supabase
          .from('interview_notes')
          .select('*, notewriter:notewriter_id(name, photo, employer)')
          .eq('consultant_id', applicant.users.user_id)
          .eq('note_type', 'Comment')
          .eq('notewriter.employer', user.employer)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setComments(data || []);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [applicant?.users?.user_id, user?.employer]);

  const handleAddNote = async () => {
    try {
      const insertData = {
        consultant_id: applicant.users.user_id,
        content: newNote,
        notewriter_id: user.user_id,
        note_type: 'Comment',
      };

      const { data, error } = await supabase
        .from('interview_notes')
        .insert(insertData)
        .select('*, notewriter:notewriter_id(name, photo)')
        .single();

      if (error) throw error;

      setComments(prevComments => [data, ...prevComments]);
      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleVideoView = async (index, filePath) => {
    try {
      // First log the view in the new video_views table
      const { error: viewError } = await supabase
        .from('video_views')
        .insert({
          user_job_id: userJobId,
          viewer_id: user.user_id,
          question_index: index,
          user_agent: navigator.userAgent,
        });
  
      if (viewError) throw viewError;
  
      // Get signed URL for video
      const { data, error: urlError } = await supabase.storage
        .from('video_responses')
        .createSignedUrl(filePath, 900); // URL valid for 15 minutes
  
      if (urlError) throw urlError;
  
      if (data.signedUrl) {
        window.open(data.signedUrl, '_blank');
        
        // Track event in Firebase Analytics
        const analytics = getAnalytics();
        logEvent(analytics, 'view_video_response', {
          user_job_id: userJobId,
          question_index: index,
          viewer_id: user.user_id
        });
      } else {
        throw new Error('No signed URL available for this video.');
      }
    } catch (error) {
      console.error("Error handling video view:", error.message);
      setVideoLoadError(error.message);
    }
  };  

  const handleTabChange = (tabName) => {
    setActiveReviewTab(tabName);
    setRatingLabel(
      tabName === 'Manager' ? 'Manager Rating' :
      tabName === 'Peer' ? 'Peer Rating' : 
      'Overall Rating'
    );
  };

  const handleReject = async () => {
    try {
      const { data } = await supabase
        .from('user_jobs')
        .select('status')
        .eq('user_job_id', userJobId)
        .single();

      const newStatus = data.status === 'Applied' ? 'Interview Rejected' : 'Contract Not Offered';

      const { error } = await supabase
        .from('user_jobs')
        .update({ status: newStatus })
        .eq('user_job_id', userJobId);

      if (error) throw error;
      navigate(-1);
    } catch (error) {
      console.error('Error rejecting applicant:', error);
    }
  };

  const handleInterview = () => {
    navigate(
      applicant.status === 'Interview Scheduled'
        ? '/app/request-second-interview/' + userJobId
        : '/app/schedule-interview/' + userJobId
    );
  };

  const startChat = async (reviewerId) => {
    try {
      const { data: existingChat, error: fetchError } = await supabase
        .from('chats')
        .select('id')
        .contains('participants', [user.user_id, reviewerId])
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      let chatId;
      if (existingChat) {
        chatId = existingChat.id;
      } else {
        const { data: newChat, error: insertError } = await supabase
          .from('chats')
          .insert({
            participants: [user.user_id, reviewerId],
          })
          .select()
          .single();

        if (insertError) throw insertError;
        chatId = newChat.id;
      }

      navigate(`/app/chats`, { state: { newChatUserId: reviewerId } });
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  const filteredReviews = reviews.filter(review => 
    activeReviewTab === 'All' || 
    review.review_type.toLowerCase() === activeReviewTab.toLowerCase()
  );

  const reviewTabs = [
    { name: 'All', count: reviews.length || null, current: activeReviewTab === 'All', onClick: () => handleTabChange('All') },
    { name: 'Peer', count: reviews.filter(r => r.review_type === 'peer').length || null, current: activeReviewTab === 'Peer', onClick: () => handleTabChange('Peer') },
    { name: 'Manager', count: reviews.filter(r => r.review_type === 'manager').length || null, current: activeReviewTab === 'Manager', onClick: () => handleTabChange('Manager') },
  ];

  const noteTabs = [
    { name: 'All Notes', count: interviewNotes.length || null, current: activeTab === 'All Notes', onClick: () => setActiveTab('All Notes') },
    { name: 'Interview', count: interviewNotes.filter(n => n.note_type === 'Interview').length || null, current: activeTab === 'Interview', onClick: () => setActiveTab('Interview') },
    { name: 'General', count: interviewNotes.filter(n => n.note_type === 'General').length || null, current: activeTab === 'General', onClick: () => setActiveTab('General') },
    { name: 'AI Summary', count: interviewNotes.filter(n => n.note_type === 'AI Summary').length || null, current: activeTab === 'AI Summary', onClick: () => setActiveTab('AI Summary') },
  ];

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (!applicant) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Applicant not found</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${applicant?.users?.name || 'Applicant'} - Details | fetchConsultant`}</title>
        <meta name="description" content={`View details for applicant ${applicant?.users?.name || ''} for the ${applicant?.job?.title || 'job'} position.`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
      <ClientHamburger isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      
      <div className="flex-1 md:ml-64 flex flex-col">
        <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>

            {/* Use isMenuOpen to conditionally render breadcrumb or back arrow */}
            {isMenuOpen ? (
              <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
                <ArrowLongLeftIcon className="h-6 w-6 text-gray-500" />
                <span className="text-gray-500">Back to Applicants</span>
              </div>
            ) : (
              <nav className="flex items-center text-sm space-x-2">
                <HomeIcon className="h-4 w-4 text-gray-500 cursor-pointer" onClick={() => navigate('/app/client-dashboard')} aria-hidden="true" />
                <span className="text-gray-400">/</span>
                <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-1)}>Applicants</span>
              </nav>
            )}
          </div>

          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>
      <main className="py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                  <img className="h-16 w-16 rounded-full" src={applicant.users?.photo || '/default-avatar.png'} alt="" />
                </div>
                <div>
                <div className="flex items-center justify-between">
                  {/* User's Name */}
                  <h1 className="text-2xl text-left font-bold text-gray-900">{applicant.users?.name}</h1>

                  {/* Fetch Recommended Badge */}
                  {applicant?.fetch_recommended && (
                    <div className="ml-4">
                      <span className="bg-blue-100 text-blue-800 text-m font-semibold mr-2 px-2.5 py-0.5 rounded relative group">
                        fC Recommended
                        {/* Tooltip for desktop */}
                        <span className="hidden lg:block absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                          fC Recommended means a certified expert reviewed your job posting and the consultant's application and deemed them a recommend match.
                        </span>
                      </span>

                      {/* Superscript 'i' for mobile */}
                      <sup className="lg:hidden ml-1 cursor-pointer" title="fC Recommended means a certified expert reviewed your job posting and the consultant's application and deemed them a recommend match.">
                        <i className="text-s text-gray-500">i</i>
                      </sup>
                    </div>
                  )}
                </div>

                <p className="text-sm text-left font-medium text-gray-500">
                  Applied for <a href="#" className="text-gray-900">{applicant.job?.title}</a> on <time dateTime={applicant.submitted_at}>{new Date(applicant.submitted_at).toLocaleDateString()}</time>
                </p>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                <button 
                  type="button" 
                  onClick={handleReject}
                  className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Reject
                </button>
                <button 
                  type="button" 
                  onClick={handleInterview}
                  className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {applicant.status === 'Interview Scheduled' ? 'Request Second Interview' : 'Schedule Interview'}
                </button>
                <button 
                  type="button" 
                  onClick={() => navigate('/app/client-offer-contract/' + userJobId)}
                  className="inline-flex items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Offer Contract
                </button>
              </div>
            </div>

            <div className="mx-auto text-left mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
              <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                {/* Applicant Information */}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                        Applicant Information
                      </h2>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">About</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <ReactQuill
                              value={applicant.users?.about || 'No information provided'}
                              readOnly={true}
                              theme="bubble"
                              modules={{ toolbar: false }}
                              style={{
                                '.ql-tooltip.ql-hidden': { display: 'none' },
                                '.ql-toolbar': { display: 'none' },
                              }}
                            />
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">Submission Notes</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                           <ReactQuill
                              value={applicant.consultant_notes || 'No information provided'}
                              readOnly={true}
                              theme="bubble"
                              modules={{ toolbar: false }}
                              style={{
                                '.ql-tooltip.ql-hidden': { display: 'none' },
                                '.ql-toolbar': { display: 'none' },
                              }}
                            />
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Location</dt>
                          <dd className="mt-1 text-sm text-gray-900">{applicant.users?.city}, {applicant.users?.state}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Consultant Rate</dt>
                          <dd className="mt-1 text-sm text-gray-900">${applicant.consultant_rate}/hr</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Current Project End Date</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {applicant.users?.current_project_end_date
                              ? format(new Date(applicant.users.current_project_end_date), 'MM/dd/yyyy')
                              : 'N/A'}
                          </dd>                        
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Available Start Date</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {applicant.available_start_date && format(new Date(applicant.available_start_date), 'MM/dd/yyyy')}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">LinkedIn</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <a href={applicant.users?.linkedin_url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                              View Profile
                            </a>
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">Epic Certifications</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <div className="flex flex-wrap">
                              {applicant.users?.user_epic_certifications.map((cert, index) => (
                                <span key={index} className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                  {cert.epic_applications.name}
                                </span>
                              ))}
                            </div>
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">Resume</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <button onClick={() => window.open(applicant.users?.resume, '_blank')} className="text-indigo-600 hover:text-indigo-900">
                              <Download className="inline-block w-5 h-5 mr-1" />
                              Download Resume
                            </button>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>

                {/* Application Responses */}
                {applicant.job?.video_questions?.length > 0 && (
                  <section aria-labelledby="responses-title">
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="responses-title" className="text-lg font-medium leading-6 text-gray-900">
                          {applicant.response_type === 'video' ? 'Video Responses' : 'Text Responses'}
                        </h2>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        {applicant.job.video_questions.map((question, index) => (
                          <div key={index} className="mb-4">
                            <h3 className="text-sm font-medium text-gray-500">{question}</h3>
                            {applicant.response_type === 'video' ? (
                              // Video response display
                              applicant.video_responses && applicant.video_responses[index] ? (
                                <div>
                                  <button
                                    className="mt-1 text-indigo-600 hover:text-indigo-900 flex items-center"
                                    onClick={async () => handleVideoView(index, applicant.video_responses[index])}
                                  >
                                    <PlayCircle className="w-5 h-5 mr-1" />
                                    Watch Response
                                  </button>
                                  {videoLoadError && (
                                    <div className="text-red-600 text-sm mt-2">
                                      Error loading video: {videoLoadError}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <p className="mt-1 text-sm text-gray-500">No response uploaded for this question</p>
                              )
                            ) : (
                              // Text response display
                              applicant.text_responses && applicant.text_responses[index] ? (
                                <div className="mt-2 text-sm text-gray-900 bg-gray-50 rounded-md p-4">
                                  {applicant.text_responses[index]}
                                </div>
                              ) : (
                                <p className="mt-1 text-sm text-gray-500">No response provided for this question</p>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                )}

                {/* Notes Section */}
                <section aria-labelledby="notes-title" className="mt-8">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">Interview Notes</h2>
                        <button
                          onClick={() => navigate('/app/create-interview-note', { state: { consultantId: applicant.users.user_id, consultantName: applicant.users.name } })}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Create Note
                        </button>
                      </div>
                      <div className="px-4 py-6 sm:px-6 overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Creator</th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {interviewNotes.filter(note => note.note_type === 'Interview' || note.note_type === 'AI Summary').map((note) => (
                              <tr key={note.id} className="cursor-pointer hover:bg-gray-50" onClick={() => setSelectedNote(note)}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{note.notewriter?.name ?? 'AI Summary'}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {note.feedback_score ? (
                                    <div className="flex">
                                      {[1, 2, 3, 4, 5].map((star) => (
                                        <Star
                                          key={star}
                                          size={16}
                                          className={star <= note.feedback_score ? 'text-yellow-400' : 'text-gray-300'}
                                          fill={star <= note.feedback_score ? 'currentColor' : 'none'}
                                        />
                                      ))}
                                    </div>
                                  ) : 'N/A'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(note.created_at).toLocaleDateString()}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <NoteModal note={selectedNote} onClose={() => setSelectedNote(null)} />
                </section>

                {/* Reviews Section */}
                <section aria-labelledby="reviews-title">
                  <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="reviews-title" className="text-lg font-medium text-gray-900">Reviews</h2>
                      </div>
                      <div className="px-4 py-6 sm:px-6">
                        {/* Dynamic Rating Label */}
                        <div className="mb-4">
                          <h3 className="text-lg font-semibold">{ratingLabel}</h3>
                          <div className="flex items-center">
                            <p className="mr-2">
                              {activeReviewTab === 'All'
                                ? applicant?.users?.average_rating || 'No ratings yet'
                                : activeReviewTab === 'Peer'
                                ? applicant?.users?.average_peer_rating || 'No ratings yet'
                                : applicant?.users?.average_manager_rating || 'No ratings yet'}
                            </p>
                            {activeReviewTab === 'All' && applicant?.users?.average_rating && renderStars(applicant.users.average_rating)}
                            {activeReviewTab === 'Peer' && applicant?.users?.average_peer_rating && renderStars(applicant.users.average_peer_rating)}
                            {activeReviewTab === 'Manager' && applicant?.users?.average_manager_rating && renderStars(applicant.users.average_manager_rating)}
                          </div>
                        </div>

                        {/* Review Tabs (All, Peer, Manager) */}
                        <TabsUnderlineBadges tabs={reviewTabs} setActiveTab={handleTabChange} /> {/* Pass handleTabChange */}

                        {/* Filtered Reviews */}
                        {filteredReviews.length > 0 ? (
                          filteredReviews.map((review) => (
                            <div key={review.review_id} className="border-b border-gray-200 py-2 cursor-pointer hover:bg-gray-50" onClick={() => setSelectedReview(review)}>
                              <div className="flex justify-between">
                                <span className="font-medium">{review.reviewer.name}</span>
                                <span className="text-sm text-gray-500">{new Date(review.created_at).toLocaleDateString()}</span>
                              </div>
                              <div className="text-sm text-gray-500">{review.review_type}</div>
                              <div className="flex items-center">
                                {[...Array(5)].map((_, i) => (
                                  <Star
                                    key={i}
                                    className={i < review.rating ? "text-yellow-400" : "text-gray-300"}
                                    fill={i < review.rating ? "currentColor" : "none"}
                                    size={16}
                                  />
                                ))}
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No reviews available</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {selectedReview && <ReviewModal review={selectedReview} onClose={closeReviewModal} onStartChat={startChat} />}
                </section>

                {/* Comments Section */}
                <section aria-labelledby="comments-title">
                  <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="comments-title" className="text-lg font-medium text-gray-900">Comments</h2>
                      </div>
                      <div className="px-4 py-6 sm:px-6">
                        <ul role="list" className="space-y-8">
                          {comments.map((comment) => (
                            <li key={comment.id}>
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                  <img className="h-10 w-10 rounded-full" src={comment.notewriter.photo || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"} alt="" />
                                </div>
                                <div>
                                  <div className="text-sm">
                                    <a href="#" className="font-medium text-gray-900">
                                      {comment.notewriter.name}
                                    </a>
                                  </div>
                                  <div className="mt-1 text-sm text-gray-700">
                                    <p>{comment.content}</p>
                                  </div>
                                  <div className="mt-2 text-sm space-x-2">
                                    <span className="text-gray-500 font-medium">{new Date(comment.created_at).toLocaleString()}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        
                        {/* Add comment form */}
                        <div className="mt-6">
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={user?.photo || '/default-avatar.png'} alt="" />
                            </div>
                            <div className="min-w-0 flex-1">
                              <form onSubmit={(e) => { e.preventDefault(); handleAddNote(); }}>
                                <div>
                                  <label htmlFor="comment" className="sr-only">Add a comment</label>
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    rows={3}
                                    className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                                    placeholder="Add a comment"
                                    value={newNote}
                                    onChange={(e) => setNewNote(e.target.value)}
                                  />
                                </div>
                                <div className="mt-3 flex items-center justify-between">
                                  <button
                                    type="submit"
                                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    Comment
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                  <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                    Timeline
                  </h2>

                  {/* Activity Feed */}
                  <div className="mt-6 flow-root">
                    <ul role="list" className="-mb-8">
                      {timeline.map((item, itemIdx) => (
                        <li key={item.id}>
                          <div className="relative pb-8">
                            {itemIdx !== timeline.length - 1 ? (
                              <span
                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div className="relative flex space-x-3">
                              <div>
                                <span
                                  className={classNames(
                                    item.type.bgColorClass,
                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                  )}
                                >
                                  <item.type.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm text-gray-500">
                                    {item.content}{' '}
                                    <span className="font-medium text-gray-900">
                                      {item.target}
                                    </span>
                                  </p>
                                  <p className="text-xs text-gray-400">
                                    Changed by: {item.changedBy}
                                  </p>
                                </div>
                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                  <time dateTime={item.datetime}>{item.date}</time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplicantDetails;