import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from 'react-helmet';
import { ChevronLeft, CheckCircle, XCircle } from 'lucide-react';

const ClientOnboardingProgress = () => {
  const [engagements, setEngagements] = useState([]);
  const [loading, setLoading] = useState(true);
  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Onboarding Progress',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchEngagements();
  }, [jobId]);

  const fetchEngagements = async () => {
    try {
      const { data: engagementsData, error: engagementsError } = await supabase
        .from('engagements')
        .select(`
          id,
          user_jobs!inner(
            user_id,
            users(name),
            job_id
          )
        `)
        .eq('user_jobs.job_id', jobId);

      if (engagementsError) throw engagementsError;

      const engagementsWithRequirements = await Promise.all(engagementsData.map(async (engagement) => {
        const { data: requirementsData, error: requirementsError } = await supabase
          .from('consultant_onboarding_status')
          .select(`
            id,
            status,
            onboarding_requirements(requirement_name, is_mandatory)
          `)
          .eq('engagement_id', engagement.id);

        if (requirementsError) throw requirementsError;

        return {
          ...engagement,
          requirements: requirementsData
        };
      }));

      setEngagements(engagementsWithRequirements);
    } catch (error) {
      console.error('Error fetching engagements:', error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Consultant Onboarding Progress | fetchConsultant</title>
        <meta name="description" content="Track the onboarding progress of consultants for your job on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
            <div className="max-w-md mx-auto">
              <div className="flex items-center space-x-5">
                <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                  <h2 className="leading-relaxed">Consultant Onboarding Progress</h2>
                </div>
              </div>
              <div className="divide-y divide-gray-200">
                {engagements.map((engagement) => (
                  <div key={engagement.id} className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <h3 className="font-bold">{engagement.user_jobs.users.name}</h3>
                    {engagement.requirements.map((requirement) => (
                      <div key={requirement.id} className="flex items-center justify-between">
                        <span>{requirement.onboarding_requirements.requirement_name}</span>
                        {requirement.status === 'Completed' ? (
                          <CheckCircle className="text-green-500" size={20} />
                        ) : (
                          <XCircle className="text-red-500" size={20} />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientOnboardingProgress;