import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import ConsultantHamburger from "./ConsultantHamburger";

const ConsultantTimesheet = () => {
  const [engagements, setEngagements] = useState([]);
  const [selectedEngagement, setSelectedEngagement] = useState('');
  const [weekStart, setWeekStart] = useState(getWeekStart(new Date()));
  const [timesheetRows, setTimesheetRows] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Timesheet',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    checkMobile();

    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (user) {
      fetchEngagements();
    }
  }, [user]);

  useEffect(() => {
    if (selectedEngagement) {
      fetchTimesheetEntries();
    }
  }, [weekStart, selectedEngagement]);

  function checkMobile() {
    setIsMobile(window.innerWidth <= 768);
  }

  const fetchUser = async () => {
    try {
      setIsLoading(true);
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      if (user) {
        setUser(user);
      } else {
        throw new Error("No authenticated user found");
      }
    } catch (error) {
      console.error('Error fetching user:', error.message);
      setError('Failed to fetch user data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  function getWeekStart(date) {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day; // Start from Sunday
    return new Date(d.setDate(diff));
  }

  const fetchEngagements = async () => {
    console.log("fetchEngagements function called");
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('engagements')
        .select('id, status')
        .eq('consultant_id', user.id)
        .eq('status', 'Active');

      if (error) throw error;

      console.log("Fetched Engagements:", data);

      if (data.length === 0) {
        setError('No active engagements found.');
      } else {
        setEngagements(data);
        setError(null);
      }
    } catch (error) {
      console.error('Error fetching engagements:', error.message);
      setError('Failed to fetch engagements. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTimesheetEntries = async () => {
    try {
      if (!user || !selectedEngagement) return;
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      const { data, error } = await supabase
        .from('timesheet_entries')
        .select('*')
        .eq('consultant_id', user.id)
        .eq('engagement_id', selectedEngagement)
        .gte('date', weekStart.toISOString().split('T')[0])
        .lte('date', weekEnd.toISOString().split('T')[0])
        .order('date', { ascending: true });

      if (error) throw error;

      // Group entries by title
      const rows = {};
      data.forEach(entry => {
        if (!rows[entry.title]) {
          rows[entry.title] = {
            title: entry.title,
            hours: Array(7).fill(''),
            entryIds: Array(7).fill(null),
          };
        }
        const dayIndex = Math.floor((new Date(entry.date) - weekStart) / (1000 * 60 * 60 * 24));
        rows[entry.title].hours[dayIndex] = entry.hours.toString();
        rows[entry.title].entryIds[dayIndex] = entry.id;
      });

      setTimesheetRows(Object.values(rows));
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const handleTitleChange = (rowIndex, value) => {
    const newRows = [...timesheetRows];
    newRows[rowIndex].title = value;
    setTimesheetRows(newRows);

    // Update titles in the database
    updateTitleForRow(rowIndex, value);
  };

  const updateTitleForRow = async (rowIndex, title) => {
    try {
      const row = timesheetRows[rowIndex];

      // For each day, update the title if an entry exists
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const entryId = row.entryIds[dayIndex];
        if (entryId) {
          const { error } = await supabase
            .from('timesheet_entries')
            .update({ title })
            .eq('id', entryId);

          if (error) throw error;
        }
      }
    } catch (error) {
      console.error('Error updating title:', error.message);
    }
  };

  const handleHourChange = (rowIndex, dayIndex, value) => {
    const newRows = [...timesheetRows];
    newRows[rowIndex].hours[dayIndex] = value;
    setTimesheetRows(newRows);

    // Update the database in real-time
    updateTimesheetEntry(rowIndex, dayIndex, value);
  };

  const updateTimesheetEntry = async (rowIndex, dayIndex, value) => {
    try {
      if (!user || !selectedEngagement) return;
      const date = new Date(weekStart);
      date.setDate(date.getDate() + dayIndex);
      const dateStr = date.toISOString().split('T')[0];

      const hours = parseFloat(value) || 0;

      const row = timesheetRows[rowIndex];
      const entryId = row.entryIds[dayIndex];

      const entry = {
        consultant_id: user.id,
        engagement_id: selectedEngagement,
        title: row.title,
        date: dateStr,
        hours: hours,
        status: 'saved'
      };

      if (entryId) {
        // Update existing entry
        const { error } = await supabase
          .from('timesheet_entries')
          .update(entry)
          .eq('id', entryId);

        if (error) throw error;
      } else {
        // Insert new entry
        const { data, error } = await supabase
          .from('timesheet_entries')
          .insert(entry)
          .select();

        if (error) throw error;

        // Update the entryId in timesheetRows
        const newRows = [...timesheetRows];
        newRows[rowIndex].entryIds[dayIndex] = data[0].id;
        setTimesheetRows(newRows);
      }
    } catch (error) {
      console.error('Error updating timesheet entry:', error.message);
    }
  };

  const rowTotal = (rowIndex) => {
    const row = timesheetRows[rowIndex];
    return row.hours.reduce((sum, h) => sum + (parseFloat(h) || 0), 0).toFixed(2);
  };

  const columnTotals = () => {
    const totals = Array(7).fill(0);
    timesheetRows.forEach(row => {
      row.hours.forEach((h, i) => {
        totals[i] += parseFloat(h) || 0;
      });
    });
    return totals.map(total => total.toFixed(2));
  };

  const grandTotal = () => {
    return timesheetRows.reduce((sum, row, rowIndex) => sum + parseFloat(rowTotal(rowIndex)), 0).toFixed(2);
  };

  const addRow = () => {
    const newRow = {
      title: '',
      hours: Array(7).fill(''),
      entryIds: Array(7).fill(null),
    };
    setTimesheetRows([...timesheetRows, newRow]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!user || !selectedEngagement) return;

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      const { error } = await supabase
        .from('timesheet_entries')
        .update({ status: 'submitted' })
        .eq('consultant_id', user.id)
        .eq('engagement_id', selectedEngagement)
        .gte('date', weekStart.toISOString().split('T')[0])
        .lte('date', weekEnd.toISOString().split('T')[0]);

      if (error) throw error;

      alert('Timesheet submitted successfully');
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error submitting timesheet:', error.message);
      alert('Error submitting timesheet. Please try again.');
    }
  };

  const changeWeek = (direction) => {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() + (direction === 'next' ? 7 : -7));
    setWeekStart(newWeekStart);
  };

  function formatDate(weekStart, dayIndex) {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + dayIndex);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  }

  return (
    <>
      <Helmet>
        <title>Consultant Timesheet | fetchConsultant</title>
        <meta name="description" content="Submit and view your weekly timesheet entries as a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <ConsultantHamburger user={user} currentPage="timesheet" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Weekly Timesheet</h1>
          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <button onClick={() => changeWeek('prev')} className="fetch-button"><ChevronLeft /></button>
                  <h2 className="text-xl font-bold">Week of {weekStart.toLocaleDateString()}</h2>
                  <button onClick={() => changeWeek('next')} className="fetch-button"><ChevronRight /></button>
                </div>
                <form onSubmit={handleSubmit} className="fetch-form">
                  <div>
                    <label htmlFor="engagement" className="fetch-label">Select Engagement</label>
                    <select
                      id="engagement"
                      value={selectedEngagement}
                      onChange={(e) => setSelectedEngagement(e.target.value)}
                      required
                      className="fetch-select"
                    >
                      <option value="">Select an engagement</option>
                      {engagements.map((engagement) => (
                        <option key={engagement.id} value={engagement.id}>
                          {engagement.id}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedEngagement && (
                    <>
                      <table className="w-full mt-4">
                        <thead>
                          <tr>
                            <th className="text-left">Title</th>
                            {daysOfWeek.map((day, index) => (
                              <th key={index} className="text-center">
                                <div>{day}</div>
                                <div>({formatDate(weekStart, index)})</div>
                              </th>
                            ))}
                            <th className="text-right">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {timesheetRows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td>
                                <input
                                  type="text"
                                  value={row.title}
                                  onChange={(e) => handleTitleChange(rowIndex, e.target.value)}
                                  className="fetch-input"
                                />
                              </td>
                              {row.hours.map((hour, dayIndex) => (
                                <td key={dayIndex}>
                                  <input
                                    type="number"
                                    value={hour}
                                    onChange={(e) => handleHourChange(rowIndex, dayIndex, e.target.value)}
                                    step="0.25"
                                    min="0"
                                    max="24"
                                    className="fetch-input"
                                  />
                                </td>
                              ))}
                              <td className="text-right">{rowTotal(rowIndex)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="text-right font-bold">Total</td>
                            {columnTotals().map((total, index) => (
                              <td key={index} className="text-right font-bold">{total}</td>
                            ))}
                            <td className="text-right font-bold">{grandTotal()}</td>
                          </tr>
                        </tfoot>
                      </table>
                      <button type="button" onClick={addRow} className="fetch-button mt-2">Add Row</button>
                      <div className="flex justify-end items-center mt-4">
                        <button type="submit" className="fetch-button bg-green-500 hover:bg-green-600">
                          Submit Timesheet
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </>
            )}
          </div>
          <div className="fetch-card mt-6">
            <h2 className="text-xl font-bold mb-4">Previous Timesheets</h2>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Week Start</th>
                  <th className="text-left">Total Hours</th>
                  <th className="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(timesheetRows.reduce((acc, row) => {
                  row.hours.forEach((h, i) => {
                    const date = new Date(weekStart);
                    date.setDate(date.getDate() + i);
                    const weekStartStr = getWeekStart(date).toISOString().split('T')[0];
                    if (!acc[weekStartStr]) {
                      acc[weekStartStr] = { totalHours: 0, status: 'saved' };
                    }
                    acc[weekStartStr].totalHours += parseFloat(h) || 0;
                  });
                  return acc;
                }, {})).map(([weekStart, week]) => (
                  <tr key={weekStart}>
                    <td>{new Date(weekStart).toLocaleDateString()}</td>
                    <td>{week.totalHours.toFixed(2)}</td>
                    <td>{week.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </>
  );
};

export default ConsultantTimesheet;