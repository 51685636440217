import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Info, Menu, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';

const ApplyJob = () => {
  const [responseType, setResponseType] = useState(''); // Default to 'video'
  const [responseOption, setResponseOption] = useState(''); // For user selection if 'either'
  const [textResponses, setTextResponses] = useState({});
  const [videoQuestions, setVideoQuestions] = useState([]);
  const [videoResponses, setVideoResponses] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [formData, setFormData] = useState({
    consultant_rate: '',
    available_start_date: '',
    consultant_notes: '',
  });
  const [formValid, setFormValid] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [rateStats, setRateStats] = useState({ high: null, low: null, average: null });
  const [showTooltip, setShowTooltip] = useState(false);
  const [job, setJob] = useState(null);
  const location = useLocation();
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Apply for Job',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobDetails();
    loadFormData();
    fetchUser();

    const fetchExistingApplication = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) return;

      try {
        const response = await fetch(`${supabaseUrl}/functions/v1/apply-job-operations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            action: 'fetchExistingApplication',
            jobId,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }

        const { data } = await response.json();

        if (data) {
          console.log('Existing application data:', data);

          // Update form data
          setFormData(prevState => {
            const newState = {
              ...prevState,
              consultant_rate: data.consultant_rate ? `$${data.consultant_rate}` : '$',
              available_start_date: data.available_start_date || '',
              consultant_notes: data.consultant_notes || '',
            };

            if (data.text_responses) {
              Object.keys(data.text_responses).forEach(index => {
                newState[`text-response-${index}`] = data.text_responses[index];
              });
            }

            return newState;
          });

          // Set response types - prioritize user's saved choice
          if (data.response_type) {
            setResponseType(job?.response_type || ''); // Set the job's response type requirement
            setResponseOption(data.response_type); // Set the user's chosen response type
          } else {
            setResponseType(job?.response_type || '');
          }

          // Set video responses
          if (data.video_responses) {
            setVideoResponses(data.video_responses);
            const fileNamesObj = {};
            Object.entries(data.video_responses).forEach(([index, path]) => {
              if (path) {
                const fileName = path.split('/').pop();
                fileNamesObj[index] = fileName;
              }
            });
            setFileNames(fileNamesObj);
          }

          // Set text responses
          if (data.text_responses) {
            setTextResponses(data.text_responses);
          }
        }
      } catch (error) {
        console.error('Error fetching existing application:', error);
      }
    };

    fetchExistingApplication();
  }, [jobId]);

  useEffect(() => {
    const isValid =
      formData.consultant_rate.trim() !== '' &&
      formData.consultant_rate !== '$' &&
      formData.available_start_date !== '';
    setFormValid(isValid);

    // Save form data, videoResponses, textReponses, and fileNames to localStorage
    localStorage.setItem(`applyJobFormData_${jobId}`, JSON.stringify(formData));
    localStorage.setItem(`applyJobVideoResponses_${jobId}`, JSON.stringify(videoResponses));
    localStorage.setItem(`applyJobTextResponses_${jobId}`, JSON.stringify(textResponses));
    localStorage.setItem(`applyJobFileNames_${jobId}`, JSON.stringify(fileNames));
  }, [formData, videoResponses, textResponses, fileNames, jobId]);

  useEffect(() => {
    const loadRateStats = async () => {
      const stats = await fetchConsultantRateStats();
      setRateStats(stats);
    };

    loadRateStats();
  }, [jobId]);

  const fetchConsultantRateStats = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No active session");

      const response = await fetch(`${supabaseUrl}/functions/v1/apply-job-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: 'fetchConsultantRateStats',
          jobId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const stats = await response.json();
      return stats;
    } catch (error) {
      console.error('Error fetching consultant rate stats:', error.message);
      return { high: null, low: null, average: null };
    }
  };

  const fetchJobDetails = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No active session");

      const response = await fetch(`${supabaseUrl}/functions/v1/apply-job-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: 'fetchJobDetails',
          jobId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      setJob(data);
      setVideoQuestions(data.video_questions || []);
      // Set response type based on job requirements
      if (data.response_type === 'text' || data.response_type === 'video') {
        setResponseType(data.response_type);
        setResponseOption(data.response_type);
      } else {
        setResponseType('either');
      }
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  const loadFormData = () => {
    const savedFormData = localStorage.getItem(`applyJobFormData_${jobId}`);
    const savedVideoResponses = localStorage.getItem(`applyJobVideoResponses_${jobId}`);
    const savedTextResponses = localStorage.getItem(`applyJobTextResponses_${jobId}`);

    const savedFileNames = localStorage.getItem(`applyJobFileNames_${jobId}`);

    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }

    if (savedVideoResponses) {
      setVideoResponses(JSON.parse(savedVideoResponses));
    }

    if (savedTextResponses) {
      setTextResponses(JSON.parse(savedTextResponses));
    }

    if (savedFileNames) {
      setFileNames(JSON.parse(savedFileNames));
    }
  };

  const fetchUser = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No active session");

      const response = await fetch(`${supabaseUrl}/functions/v1/apply-job-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: 'fetchUser',
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'consultant_rate') {
      // Remove non-digit characters and add $ sign
      const numericValue = value.replace(/[^\d]/g, '');
      setFormData(prevState => ({
        ...prevState,
        [name]: numericValue ? `$${numericValue}` : '$'
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleRichTextChange = (content) => {
    setFormData(prevState => ({
      ...prevState,
      consultant_notes: content
    }));
  };

  const [videoUploadsInProgress, setVideoUploadsInProgress] = useState({}); // To track video upload status

  const handleVideoUpload = async (e, questionIndex) => {
    const file = e.target.files[0];

    if (file.size > 50 * 1024 * 1024) {
      alert("File size must be less than 50MB");
      return;
    }
    if (!file.type.startsWith("video/")) {
      alert("Only video files are allowed");
      return;
    }

    try {
      // Mark the upload as in progress
      setVideoUploadsInProgress(prev => ({ ...prev, [questionIndex]: true }));

      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = `video_responses/${jobId}/${fileName}`; // Ensure correct path

      const { data, error } = await supabase.storage
        .from('video_responses')
        .upload(filePath, file);

      if (error) throw error;

      const { publicUrl, error: urlError } = supabase.storage
        .from('video_responses')
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      // Update state once the video is uploaded
      setVideoResponses(prev => ({
        ...prev,
        [questionIndex]: filePath
      }));

      setFileNames(prev => ({
        ...prev,
        [questionIndex]: file.name
      }));

      // Mark the upload as complete
      setVideoUploadsInProgress(prev => ({ ...prev, [questionIndex]: false }));

    } catch (error) {
      console.error('Error uploading video:', error);
      alert(`Error uploading video: ${error.message}`);
    }
  };

  // Check if any video uploads are still in progress before submitting
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formValid) return;

    if (Object.values(videoUploadsInProgress).some(upload => upload)) {
      alert("Please wait for all videos to finish uploading before proceeding.");
      return;
    }

    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No active session");

      // Prepare data to send
      const applicationData = {
        consultant_rate: parseFloat(formData.consultant_rate.replace('$', '')),
        available_start_date: formData.available_start_date,
        consultant_notes: formData.consultant_notes,
        text_responses: {},
        video_responses: {},
        response_type: responseOption || responseType,
      };

      // Prepare text responses
      if (responseOption === 'text' || responseType === 'text') {
        applicationData.text_responses = videoQuestions.reduce((obj, _, index) => {
          obj[index] = formData[`text-response-${index}`] || '';
          return obj;
        }, {});
      }

      // Prepare video responses
      if (responseOption === 'video' || responseType === 'video') {
        applicationData.video_responses = videoResponses;
      }

      const response = await fetch(`${supabaseUrl}/functions/v1/apply-job-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: 'upsertApplication',
          jobId,
          data: applicationData,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      console.log('Application created/updated successfully:', data);

      localStorage.removeItem(`applyJobFormData_${jobId}`);
      localStorage.removeItem(`applyJobVideoResponses_${jobId}`);
      localStorage.removeItem(`applyJobFileNames_${jobId}`);
      navigate(`/app/apply-job-interview/${jobId}`);
    } catch (error) {
      console.error('Error creating/updating application:', error.message);
      alert(`Error creating/updating application: ${error.message}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Apply for Job | fetchConsultant</title>
        <meta name="description" content="Apply for an Epic consulting position on fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Apply for Job | fetchConsultant" />
        <meta property="og:description" content="Submit your hourly rate directly to the client." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/apply-job/:jobId" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="apply-job" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Apply for Job</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="fetch-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              <div className="mb-6">
                <p className="fetch-secondary-text">Step 1 of 2</p>
                <div className="fetch-progress-bar">
                  <div className="fetch-progress-bar-fill" style={{width: '50%'}}></div>
                </div>
              </div>
              <form onSubmit={handleSubmit} className="fetch-form">
                <div className="relative">
                  <label htmlFor="consultant_rate" className="fetch-label flex items-center relative group">
                    My Hourly Rate<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />

                    {/* Tooltip for desktop (on hover) */}
                    {showTooltip && (
                      <div className="tooltip">
                        {/* Static message */}
                        <p>Consultant rates typically range from 10-20% more than what you were getting paid before.</p>
                        {/* Show high and low if available */}
                        {rateStats.high !== null && <p>High: ${rateStats.high}</p>}
                        {rateStats.low !== null && <p>Low: ${rateStats.low}</p>}
                        
                        {/* Show average if more than one matching record */}

                        {rateStats.average !== null && <p>Average: ${rateStats.average}</p>}
                      </div>
                    )}
                  </label>

                  {/* Input Field */}
                  <input
                    type="text"
                    id="consultant_rate"
                    name="consultant_rate"
                    value={formData.consultant_rate}
                    onChange={handleInputChange}
                    className="fetch-input"
                    placeholder="$100"
                    required
                  />
                </div>
              <div>
                <label htmlFor="available_start_date" className="fetch-label">Available Start Date<span className="fetch-required">*</span></label>
                <div className="relative">
                  <input
                    type="date"
                    id="available_start_date"
                    name="available_start_date"
                    value={formData.available_start_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="consultant_notes" className="fetch-label flex items-center relative group">Consultant Notes for Client
                  <Info
                    className="ml-2 text-blue-500 cursor-pointer"
                    size={18}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  />
                  {showTooltip && (
                    <div className="tooltip">
                      These notes will be shared with the client in addition to your About section and LinkedIn url from your profile. Think of this as your cover letter for this specific role.
                    </div>
                  )}
                </label>
                <ReactQuill
                  theme="snow"
                  value={formData.consultant_notes}
                  onChange={handleRichTextChange}
                  className="fetch-input"
                  modules={{
                      toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{'list': 'ordered'}, {'list': 'bullet'}],
                      ['link'],
                      ],
                  }}
                />
              </div>

              {/* Only show response type selection if job allows either */}
              {responseType === 'either' && (
                <div>
                  <label htmlFor="response_option" className="fetch-label flex items-center relative group">
                    Response Type
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    
                    {showTooltip && (
                      <div className="tooltip">
                        The client has no preference whether you reply with a video or a text response.
                      </div>
                    )}
                  </label>
                  <select
                    id="response_option"
                    name="response_option"
                    value={responseOption}
                    onChange={(e) => setResponseOption(e.target.value)}
                    className="fetch-select"
                    required
                  >
                    <option value="">Select response type</option>
                    <option value="text">Text</option>
                    <option value="video">Video</option>
                  </select>
                </div>
              )}

              {/* Render questions and inputs based on responseType or responseOption */}
              {videoQuestions.length > 0 && videoQuestions.map((question, index) => (
                <div key={index} className="mb-4">
                  <label className="fetch-label flex items-center relative group">{question}
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    
                    {showTooltip && (
                      <div className="tooltip">
                      {job?.response_type === 'either' 
                        ? "The client has no preference whether you reply with a video or a text response."
                        : `The client requires ${job?.response_type} responses for this position.`}
                    </div>
                  )}
                  </label>

                  {/* Conditionally render Textarea or Video Upload depending on selection */}
                  {((responseType === 'text' || responseOption === 'text') && (
                      <textarea
                        id={`text-response-${index}`}
                        name={`text-response-${index}`}
                        value={formData[`text-response-${index}`] || textResponses[index] || ''}
                        onChange={handleInputChange}
                        rows="4"
                        className="fetch-textarea"
                      ></textarea>
                    )) || ((responseType === 'video' || responseOption === 'video') && (

                    <div>
                      <div className="mt-1 flex items-center">
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(e) => handleVideoUpload(e, index)}
                          className="hidden"
                          id={`video-upload-${index}`}
                        />
                        <label htmlFor={`video-upload-${index}`} className="file-input-button">
                          <Upload className="w-5 h-5 mr-2" />
                          {fileNames[index] ? fileNames[index] : 'Upload Response'}
                        </label>
                        {videoResponses[index] && (
                          <span className="ml-3 text-sm text-gray-600">
                            Video uploaded successfully
                          </span>
                        )}
                      </div>

                      {/* Show file size restriction only if the user is uploading videos */}
                      <p className="text-sm text-left text-gray-500">Max size: 50MB, only video files are allowed.</p>
                    </div>
                  ))}
                </div>
              ))}
              
              <div className="mt-6">
              <button
                type="submit"
                className={`fetch-button ${!formValid || Object.values(videoUploadsInProgress).some(upload => upload) ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!formValid || Object.values(videoUploadsInProgress).some(upload => upload)}
              >
                Next
              </button>
              </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplyJob;