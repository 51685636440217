import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from 'react-helmet';
import { ChevronLeft, Upload } from 'lucide-react';

const ConsultantOnboarding = () => {
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const { engagementId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Onboarding',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchRequirements();
  }, [engagementId]);

  const fetchRequirements = async () => {
    try {
      const { data, error } = await supabase
        .from('consultant_onboarding_status')
        .select(`
          id,
          status,
          uploaded_file_url,
          onboarding_requirements (
            id,
            requirement_name,
            is_mandatory
          )
        `)
        .eq('engagement_id', engagementId);

      if (error) throw error;
      setRequirements(data);
    } catch (error) {
      console.error('Error fetching requirements:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (requirementId, file) => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = `onboarding/${engagementId}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('onboarding_documents')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('onboarding_documents')
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      const { error: updateError } = await supabase
        .from('consultant_onboarding_status')
        .update({
          status: 'Completed',
          uploaded_file_url: publicUrl
        })
        .eq('id', requirementId);

      if (updateError) throw updateError;

      fetchRequirements();
    } catch (error) {
      console.error('Error uploading file:', error.message);
      alert('Error uploading file. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Onboarding Requirements | fetchConsultant</title>
        <meta name="description" content="Complete your onboarding requirements for your new engagement on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
            <div className="max-w-md mx-auto">
              <div className="flex items-center space-x-5">
                <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                  <h2 className="leading-relaxed">Onboarding Requirements</h2>
                </div>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  {requirements.map((requirement) => (
                    <div key={requirement.id} className="flex items-center justify-between">
                      <div>
                        <p>{requirement.onboarding_requirements.requirement_name}</p>
                        <p className="text-sm text-gray-500">
                          {requirement.onboarding_requirements.is_mandatory ? 'Mandatory' : 'Optional'}
                        </p>
                      </div>
                      {requirement.status === 'Completed' ? (
                        <span className="text-green-500">Completed</span>
                      ) : (
                        <label className="cursor-pointer bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300">
                          <input
                            type="file"
                            className="hidden"
                            onChange={(e) => handleFileUpload(requirement.id, e.target.files[0])}
                          />
                          <Upload className="inline-block mr-2" size={20} />
                          Upload
                        </label>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsultantOnboarding;