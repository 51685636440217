import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserRecord, handleReferral, getUserType } from '../supabaseAuth';
import { supabase } from '../supabaseClient';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error.message);
        navigate('/app/auth');
        return;
      }

      if (session && session.user) {
        try {
          await createUserRecord(session.user.id, session.user.email);

          // Handle referral acceptance
          const urlParams = new URLSearchParams(window.location.search);
          const referralToken = urlParams.get('referral');

          if (referralToken) {
            try {
              await handleReferral(session.user.id, referralToken);
            } catch (error) {
              console.error('Error handling referral:', error);
              // Optionally display an error message or redirect
            }
          }

          const userTypeId = await getUserType(session.user.id);

          switch (userTypeId) {
            case 1:
              navigate('/app/client-or-consultant');
              break;
            case 2:
              navigate('/app/consultant-dashboard');
              break;
            case 3:
              navigate('/app/client-onboarding-personal');
              break;
            case 4:
              navigate('/app/client-dashboard');
              break;
            case 5:
              navigate('/app/system-admin-dashboard');
              break;
            default:
              navigate('/app/client-or-consultant');
          }
        } catch (error) {
          console.error('Error handling auth callback:', error.message);
          navigate('/app/auth');
        }
      } else {
        navigate('/app/auth');
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Finalizing your account setup...</div>;
};

export default AuthCallback;