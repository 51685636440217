import { getAnalytics, logEvent } from "firebase/analytics";
import debounce from 'lodash/debounce';
import { Info, Menu, Plus, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import { MultiSelect } from "react-multi-select-component";

const AddJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobStatus, setJobStatus] = useState(null);
  const [responseType, setResponseType] = useState('video'); // Default to text response
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [formData, setFormData] = useState({
    job_id: jobId || '', // Use existing jobId or empty string for new jobs
    client_id: '',
    title: '',
    description: '',
    ideal_start_date: '',
    duration: '',
    extension_possibility: '',
    travel: '',
    city: '',
    state: '',
    experience: '',
    education: '',
    referral_bonus: '',
    status: 'Draft',
    terms_and_conditions: false
  });
  const [epicApplications, setEpicApplications] = useState([]);
  const [selectedEpicCertifications, setSelectedEpicCertifications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: jobId ? 'Edit Job' : 'Post Contract',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    fetchInitialData();
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [jobId]);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error("No active session");
  
      const accessToken = session.access_token;
  
      // Get user data first
      const userResponse = await fetch(`${supabaseUrl}/functions/v1/user-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchUser',
          userId: session.user.id
        })
      });
  
      if (!userResponse.ok) {
        const errorData = await userResponse.json();
        throw new Error(errorData.error);
      }
  
      const userData = await userResponse.json();
      setUser(userData.data);
  
      // Get Epic applications
      const epicResponse = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchEpicApplications',
          userId: session.user.id
        })
      });
  
      if (!epicResponse.ok) {
        const errorData = await epicResponse.json();
        throw new Error(errorData.error);
      }
  
      const epicData = await epicResponse.json();
      setEpicApplications(epicData.data);
  
      // If editing existing job, get job details
      if (jobId) {
        console.log('Fetching job details for:', jobId);
        const jobResponse = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            action: 'fetchJobDetails',
            userId: session.user.id,
            jobId
          })
        });
  
        if (!jobResponse.ok) {
          const errorData = await jobResponse.json();
          throw new Error(errorData.error);
        }
  
        const { data: jobData } = await jobResponse.json();
        console.log('Received job data:', jobData);
  
        // Set form data
        setFormData({
          job_id: jobData.job_id,
          client_id: jobData.client_id,
          title: jobData.title || '',
          description: jobData.description || '',
          experience: jobData.experience || '',
          education: jobData.education || '',
          duration: jobData.duration || '',
          extension_possibility: jobData.extension_possibility || '',
          travel: jobData.travel || '',
          ideal_start_date: jobData.ideal_start_date || '',
          street_address: jobData.street_address || '',
          city: jobData.city || '',
          state: jobData.state || '',
          zip_code: jobData.zip_code || '',
          terms_and_conditions: jobData.terms_and_conditions || false,
          status: jobData.status || 'Draft',
          referral_bonus: jobData.referral_bonus || 0,
          response_type: jobData.response_type || 'video'
        });
  
        setJobStatus(jobData.status);
        setQuestions(jobData.video_questions || []);
        setResponseType(jobData.response_type || 'video');
        
        // Handle epic certifications
        if (jobData.jobs_epic_certifications && Array.isArray(jobData.jobs_epic_certifications)) {
          console.log('Processing certifications:', jobData.jobs_epic_certifications);
          const certifications = jobData.jobs_epic_certifications
            .map(cert => ({
              label: epicData.data.find(app => app.epic_application_id === cert.epic_application_id)?.name,
              value: cert.epic_application_id
            }))
            .filter(cert => cert.label); // Filter out any undefined labels
          
          console.log('Processed certifications:', certifications);
          setSelectedEpicCertifications(certifications);
        }
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setErrorMessage(`Failed to load data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    };
  
    // Handle referral bonus based on duration
    if (name === 'duration') {
      let bonus = 0;
      if (value === '3 Months') bonus = 1000;
      else if (value === '6 Months') bonus = 2000;
      else if (value === '12 Months') bonus = 3000;
      updatedFormData.referral_bonus = bonus;
    }
  
    setFormData(updatedFormData);
    
    // Validate form after each change
    validateForm(updatedFormData);
  
    // If it's the title field and we have a job_id, save it
    if (name === 'title' && formData.job_id) {
      debouncedSaveTitle(formData.job_id, value);
    }
  };
  
  const debouncedSaveTitle = debounce(async (jobId, title) => {
    try {
      setIsSaving(true);
      const { data: { session } } = await supabase.auth.getSession();
      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          action: 'saveTitle',
          userId: user.user_id,
          jobId,
          data: { title }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error saving title:', error);
      setErrorMessage('Failed to save title. Changes will not be preserved.');
    } finally {
      setIsSaving(false);
    }
  }, 500);
  
  const handleEpicCertificationChange = (selected) => {
    setSelectedEpicCertifications(selected);
    if (formData.job_id) {
      debouncedSaveEpicCertifications(formData.job_id, selected.map(item => item.value));
    }
    validateForm({ ...formData, epicCertifications: selected });
  };
  
  const debouncedSaveEpicCertifications = debounce(async (jobId, certifications) => {
    try {
      setIsSaving(true);
      const { data: { session } } = await supabase.auth.getSession();
      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          action: 'saveEpicCertifications',
          userId: user.user_id,
          jobId,
          data: { epicCertifications: certifications }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error saving certifications:', error);
      setErrorMessage('Failed to save certifications. Changes will not be preserved.');
    } finally {
      setIsSaving(false);
    }
  }, 500);
  
  const validateForm = (data) => {
    let isValid = true;
    
    // Required fields validation
    const requiredFields = ['title', 'description', 'ideal_start_date', 'duration', 
      'extension_possibility', 'travel'];
    
    for (const field of requiredFields) {
      if (!data[field]) {
        isValid = false;
        break;
      }
    }
  
    // Location validation for non-remote jobs
    if (data.travel !== '100% Remote') {
      if (!data.city || !data.state) {
        isValid = false;
      }
    }
  
    // Epic certifications validation
    if (!selectedEpicCertifications || selectedEpicCertifications.length === 0) {
      isValid = false;
    }
  
    setFormValid(isValid);
    return isValid;
  };

  const handleRichTextChange = (content, editor) => {
    const name = editor.getEditor().container.dataset.name;
    setFormData(prevState => ({
      ...prevState,
      [name]: content
    }));
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      const analytics = getAnalytics();
      logEvent(analytics, 'add_job_question', {
        question_count: questions.length + 1
      });
      setQuestions([...questions, newQuestion.trim()]);
      setNewQuestion('');
    }
  };

  const handleRemoveQuestion = (index) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'remove_job_question', {
      question_count: questions.length - 1
    });
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleSubmit = async (e, isDraft = false) => {
    e.preventDefault();
    if (!isDraft && !formValid) return;
  
    try {
      setIsLoading(true);
      setErrorMessage('');
      
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
  
      // Capture the current state of questions, including the newQuestion if it's not empty
      const currentQuestions = newQuestion.trim()
        ? [...questions, newQuestion.trim()]
        : questions;
  
      const jobData = {
        ...formData,
        status: formData.status && jobId ? formData.status : (isDraft ? 'Draft' : 'Pending TC'),
        video_questions: currentQuestions,
        client_id: user.employer,
        response_type: responseType,
      };
  
      // Generate new job_id if needed
      if (!jobData.job_id) {
        jobData.job_id = uuidv4();
      }
  
      // Remove city and state if travel is 100% Remote
      if (jobData.travel === '100% Remote') {
        delete jobData.city;
        delete jobData.state;
      }
  
      console.log('Submitting job data:', {
        action: jobId ? 'updateJob' : 'saveJob',
        jobId,
        jobData,
        epicCertifications: selectedEpicCertifications
      });
  
      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: jobId ? 'updateJob' : 'saveJob',
          userId: user.user_id,
          jobId,
          data: {
            jobData,
            epicCertifications: selectedEpicCertifications.map(cert => cert.value),
            questions: currentQuestions,
            isDraft
          }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      const { data } = await response.json();
      
      // Track analytics
      const analytics = getAnalytics();
      logEvent(analytics, jobId ? 'update_job' : 'create_job', {
        isDraft,
        job_id: data.job_id
      });
  
      if (!isDraft) {
        if (jobStatus !== 'Active') {
          navigate(`/app/client-add-job-terms-and-conditions/${data.job_id}`);
        } else {
          alert('Job updated successfully!');
          navigate('/app/client-dashboard');
        }
      } else {
        alert('Draft saved successfully!');
        navigate('/app/client-dashboard');
      }
    } catch (error) {
      console.error('Error saving job:', error);
      setErrorMessage(`Error saving job: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {jobId && jobStatus !== 'Draft' ? 'Update Contract' : 'Post Contract'} | fetchConsultant
        </title>
        <meta name="description" content={jobId && jobStatus !== 'Draft'
          ? "Update an existing contract opportunity for Epic consultants on fetchConsultant."
          : "Post a contract opportunity for Epic consultants on fetchConsultant. Find the best healthcare IT talent for your organization."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Personal Information | fetchConsultant" />
        <meta property="og:description" content="Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/client-onboarding-personal" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="addjob" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">
                {jobId && jobStatus !== 'Draft' ? 'Update Contract' : 'Post Contract'}
              </h1>
            </div>
            <div className="flex items-center">
              {isSaving && (
                <span className="mr-4 text-sm text-gray-500">Saving...</span>
              )}
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brandPrimary"></div>
              </div>
            ) : (
              <div className="fetch-card">
                {errorMessage && (
                  <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{errorMessage}</p>
                      </div>
                    </div>
                  </div>
                )}

                <form onSubmit={(e) => handleSubmit(e, false)} className="fetch-form">

            <div>
                <label htmlFor="title" className="fetch-label">Job Title<span className="fetch-required">*</span></label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="fetch-input"
                  placeholder="e.g. Ambulatory PM"
                  required
                />
              </div>

              <div>
                <label htmlFor="description" className="fetch-label flex items-center relative group">Job Description<span className="fetch-required">*</span>
                  <Info
                    className="ml-2 text-blue-500 cursor-pointer"
                    size={18}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  />
                  {showTooltip && (
                    <div className="tooltip">
                      Be as brief and informal as you would like. Great consultants will understand the need. Remember, there is no account manager gatekeeper who may lack domain expertise who this must first filter through. 
                    </div>
                  )}
                </label>
                <ReactQuill
                  theme="snow"
                  value={formData.description}
                  onChange={(content) => handleRichTextChange(content, { getEditor: () => ({ container: { dataset: { name: 'description' } } }) })}
                  rows="4"
                  className="fetch-input tall-editor"
                  data-name="description"
                />
              </div>

              <div>
                <label htmlFor="ideal_start_date" className="fetch-label">Ideal Start Date<span className="fetch-required">*</span></label>
                <div className="relative">
                  <input
                    type="date"
                    name="ideal_start_date"
                    id="ideal_start_date"
                    value={formData.ideal_start_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="duration" className="fetch-label">Duration<span className="fetch-required">*</span></label>
                <select
                  id="duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select duration</option>
                  <option value="3 Months">3 Months</option>
                  <option value="6 Months">6 Months</option>
                  <option value="12 Months">12 Months</option>
                </select>
              </div>

              <div>
                <label htmlFor="extension_possibility" className="fetch-label">Extension Possibility<span className="fetch-required">*</span></label>
                <select
                  id="extension_possibility"
                  name="extension_possibility"
                  value={formData.extension_possibility}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select possibility</option>
                  <option value="Likely">Likely</option>
                  <option value="Not Likely">Not Likely</option>
                </select>
              </div>

              <div>
                <label htmlFor="travel" className="fetch-label">Travel Requirement<span className="fetch-required">*</span></label>
                <select
                  id="travel"
                  name="travel"
                  value={formData.travel}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select requirement</option>
                  <option value="100% Remote">100% Remote</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="100% Onsite">100% Onsite</option>
                </select>
              </div>

            {formData.travel !== '100% Remote' && (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="city" className="fetch-label">City<span className="fetch-required">*</span></label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="state" className="fetch-label">State<span className="fetch-required">*</span></label>
                  <select
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="fetch-select"
                    required
                  >
                    <option value="">Select state</option>
                    {/* Add options for all states */}
                    <option value="TX">TX</option>
                    {/* ... other states ... */}
                  </select>
                </div>
              </div>
            )}

              <div>
                <label htmlFor="epic_certifications" className="fetch-label">Epic Certification(s) Required<span className="fetch-required">*</span></label>
                <MultiSelect
                  options={epicApplications.map(app => ({ label: app.name, value: app.epic_application_id }))}
                  value={selectedEpicCertifications}
                  onChange={handleEpicCertificationChange}
                  labelledBy="Select Epic Certifications"
                  className="fetch-multiselect"
                  hasSelectAll={false}
                />
              </div>

              <div>
                <label htmlFor="experience" className="fetch-label">Minimum Experience</label>
                <ReactQuill
                  theme="snow"
                  value={formData.experience}
                  onChange={(content) => handleRichTextChange(content, { getEditor: () => ({ container: { dataset: { name: 'experience' } } }) })}
                  className="fetch-input tall-editor"
                  data-name="experience"
                />
              </div>

              <div>
                <label htmlFor="education" className="fetch-label">Education Required</label>
                <select
                  id="education"
                  name="education"
                  value={formData.education}
                  onChange={handleInputChange}
                  className="fetch-select"
                >
                  <option value="">Select education</option>
                  <option value="Bachelor's Degree">Bachelor's Degree</option>
                  <option value="Master's Degree">Master's Degree</option>
                  <option value="None">None</option>
                </select>
              </div>

              <div>
                <label htmlFor="referral_bonus" className="fetch-label flex items-center relative group">Referral Bonus
                  <Info
                    className="ml-2 text-blue-500 cursor-pointer"
                    size={18}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  />
                  {showTooltip && (
                    <div className="tooltip">
                      fetchConsultant covers this cost to incentivize consultants to refer qualified colleagues. This maximizes exposure and minimizes fullfillment time.
                    </div>
                  )}
                </label>

                <p className="fetch-input text-left" style={{ padding: '10px' }}>
                  {formData.referral_bonus === 1000 && '$1,000'}
                  {formData.referral_bonus === 2000 && '$2,000'}
                  {formData.referral_bonus === 3000 && '$3,000'}
                </p>
              </div>

              <div>
                <label htmlFor="response_type" className="fetch-label">Response Type<span className="fetch-required">*</span></label>
                <select
                  id="response_type"
                  name="response_type"
                  value={responseType}
                  onChange={(e) => setResponseType(e.target.value)}
                  className="fetch-select"
                  required
                >
                  <option value="text">Text</option>
                  <option value="video">Video</option>
                  <option value="either">Either</option>
                </select>
              </div>

              <div className="mb-6">
                <h3 className="fetch-label flex items-center relative group mb-1">Video Response Questions
                  <Info
                    className="ml-2 text-blue-500 cursor-pointer"
                    size={18}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  />
                  {showTooltip && (
                    <div className="tooltip">
                      Within the first five minutes of an interview you know whether a consultant has potential. Save time by having them provide two-minute video responses to your top questions when they apply. 
                    </div>
                  )}
                </h3>
                  {questions.map((question, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={question}
                      onChange={(e) => handleQuestionChange(index, e.target.value)}
                      className="fetch-input flex-grow mr-2"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                ))}
                <div className="flex items-center">
                  <input
                    type="text"
                    value={newQuestion}
                    onChange={(e) => setNewQuestion(e.target.value)}
                    placeholder="Enter a new question."
                    className="fetch-input flex-grow mr-2"
                 />
                  <button
                    type="button"
                    onClick={handleAddQuestion}
                    className="bg-brandPrimary text-white p-2 rounded-md hover:bg-brandHover"
                  >
                    <Plus size={20} />
                  </button>
                </div>
              </div>

              <div className="flex justify-between">
                    {jobStatus !== 'Active' && (
                      <button
                        type="button"
                        onClick={(e) => handleSubmit(e, true)}
                        className={`fetch-button bg-gray-500 hover:bg-gray-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Saving...' : 'Save Draft'}
                      </button>
                    )}

                    {jobId && jobStatus !== 'Draft' && (
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="fetch-button bg-gray-500 hover:bg-gray-600"
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                    )}

                    <button
                      type="submit"
                      className={`fetch-button ${(!formValid || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={!formValid || isLoading}
                    >
                      {isLoading ? 'Saving...' : (jobId && jobStatus !== 'Draft' ? 'Update Contract' : 'Post Contract')}
                    </button>
                  </div>

                  {!formValid && (
                    <p className="mt-2 text-sm text-red-600">
                      Please fill in all required fields marked with *
                    </p>
                  )}
                </form>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
);
};

export default AddJob;